import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_interval_tag = _resolveComponent("interval-tag")
  const _component_custom_select = _resolveComponent("custom-select")
  const _component_panel_list = _resolveComponent("panel-list")

  return (_openBlock(), _createBlock(_component_panel_list, {
    column: _ctx.panelListColumn,
    data: _ctx.formData
  }, {
    IntervalType: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_interval_tag, {
          class: "m-right-lg",
          title: _ctx.$wordList.AutoRenewMonthly,
          active: _ctx.formData.IntervalType===0,
          imgSrc: require('@/assets/image/subcrpitions/monthly.png'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeIntervalType(0)))
        }, null, 8, ["title", "active", "imgSrc"]),
        _createVNode(_component_interval_tag, {
          class: "m-right-lg",
          title: _ctx.$wordList.ManualOperation,
          active: _ctx.formData.IntervalType===100,
          imgSrc: require('@/assets/image/subcrpitions/manual.png'),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeIntervalType(100)))
        }, null, 8, ["title", "active", "imgSrc"]),
        (_ctx.isDevOrTest)
          ? (_openBlock(), _createBlock(_component_interval_tag, {
              key: 0,
              title: "day(test)",
              active: _ctx.formData.IntervalType===3,
              imgSrc: require('@/assets/image/subcrpitions/manual.png'),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeIntervalType(3)))
            }, null, 8, ["active", "imgSrc"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode("div", { class: "p-left-sm" }, _toDisplayString(_ctx.selectList[0]&&_ctx.selectList[0].TimeZone), 513), [
          [_vShow, _ctx.formData.IntervalType!==100]
        ])
      ])
    ]),
    Cycles: _withCtx(() => [
      _createVNode(_component_custom_select, {
        modelValue: _ctx.formData.Cycles,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formData.Cycles = $event)),
        filterable: "",
        options: _ctx.cyclesOptions
      }, null, 8, ["modelValue", "options"])
    ]),
    numberTitle: _withCtx((scope) => [
      _createVNode("b", null, _toDisplayString(scope.labelValue), 1)
    ]),
    number: _withCtx(() => [
      _createVNode("b", null, _toDisplayString(_ctx.selectList.length), 1)
    ]),
    TotalPriceTitle: _withCtx((scope) => [
      _createVNode("b", null, _toDisplayString(scope.labelValue), 1)
    ]),
    TotalPrice: _withCtx(() => [
      (_ctx.computePriceLoading)
        ? (_openBlock(), _createBlock("span", _hoisted_2, [
            _createVNode("b", null, _toDisplayString(_ctx.$wordList.Calculating), 1)
          ]))
        : (_openBlock(), _createBlock("span", _hoisted_3, [
            _createVNode("b", null, "$" + _toDisplayString(_ctx.formData.TotalPrice), 1)
          ]))
    ]),
    _: 1
  }, 8, ["column", "data"]))
}